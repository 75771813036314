import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Head from "../components/head"
import Layout from "../components/layout"
import Share from "../components/share"

import baseStyles from "../pages/base.module.scss"
import blogStyles from "./blog.module.scss"
import calendar from "../images/calendar.svg"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
      headerImage {
        file {
          url
        }
      }
    }
  }
`



class Blog extends React.Component {
  imageOptions = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  state = {
    opacity: 0,
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      try {
        const num = document.getElementById("scroll").getBoundingClientRect().top / 220;
        this.setState({
          opacity: 1 - (num * -1)
        })
      } catch (e) {
        clearInterval(this.interval)
      }
    }, 200);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {data: { contentfulBlogPost = {} } = {}} = this.props;

    return (
      <>
      <Head title={contentfulBlogPost.title} />
      <Layout>
        <div className={baseStyles.wrapper}>
          <div className={baseStyles.container}>
            <ul>
              <li
                id="scroll"
                className={`${baseStyles.listItem} ${blogStyles.splash}`}
                style={{
                  backgroundImage: `url(${contentfulBlogPost.headerImage.file.url})`,
                  filter: `brightness(${Math.max(this.state.opacity)})`
                }}
                ></li>
              <li 
              className={`${blogStyles.blogContainer} ${baseStyles.listItem}`}>
                <div className={blogStyles.content}>
                  <h1>{contentfulBlogPost.title}</h1>
                  <div className={blogStyles.date}>
                    <img
                      src={calendar}
                      className={blogStyles.icon}
                      alt="Date"
                      />
                    <p>{contentfulBlogPost.publishedDate}</p>
                  </div>
                  {documentToReactComponents(
                    contentfulBlogPost.body.json,
                    this.imageOptions
                    )}
                  <Share
                    url={`https://www.bill-geoghegan.com${this.props.path}`}
                    title={contentfulBlogPost.title}
                    />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  )
}
}

export default Blog
