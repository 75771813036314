import React from "react"

import shareStyles from "./share.module.scss"
import share from "../images/share/share.svg"
import facebook from "../images/share/facebook.svg"
import linkedIn from "../images/share/linkedIn.svg"
import twitter from "../images/share/twitter.svg"
import reddit from "../images/share/reddit.svg"
import clipboard from "../images/share/clipboard.svg"

const Share = (url, title) => {
  return (
    <div className={shareStyles.share}>
      <div className={shareStyles.parent}>
        <img src={share} alt="share" />
      </div>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${url.url}`}>
        <div className={`${shareStyles.button} ${shareStyles.facebook}`}>
          <img src={facebook} alt="facebook" />
        </div>
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url.url}`}
      >
        <div className={`${shareStyles.button} ${shareStyles.linkedin}`}>
          <img src={linkedIn} alt="linkedIn" />
        </div>
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${url.url}`}>
        <div className={`${shareStyles.button} ${shareStyles.twitter}`}>
          <img src={twitter} alt="twitter" />
        </div>
      </a>
      <a href={`http://www.reddit.com/submit?url=${url.url}`}>
        <div className={`${shareStyles.button} ${shareStyles.reddit}`}>
          <img src={reddit} alt="reddit" />
        </div>
      </a>
      <a href={url.url}>
        <div className={`${shareStyles.button} ${shareStyles.clipboard}`}>
          <img src={clipboard} alt="clipboard" />
        </div>
      </a>
    </div>
  )
}

export default Share
